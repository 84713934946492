<template>
    <div class="infos mobile">
        <!-- Apartment Info -->
        <div class="info" @click="editAction(1)">
            <i class="fas fa-bookmark start"></i>
            <div class="text title">
                {{ name !== "" && name !== null ? name : "--" }}
            </div>
            <i class="fas fa-pencil-alt modify-icon end"></i>
        </div>
        <div class="info" @click="editAction(1)">
            <i class="fas fa-map-marker-alt start"></i>
            <div class="text title">
                {{ formattedFloor }}
            </div>
            <i class="fas fa-pencil-alt modify-icon end"></i>
        </div>
        <div class="info" @click="editAction(1)">
            <i class="fas fa-comment start"></i>
            <div class="text comment">
                {{ comment }}
            </div>
            <i class="fas fa-pencil-alt modify-icon end"></i>
        </div>
    </div>
    <!-- Switch Apartment Mobile -->
    <div class="mobile-apart-switch no-mobile">
        <p>{{ $t("global.entities.apartment") }} :</p>
        <div class="apart-info">
            <div class="left-switch" @click="previousApartment">
                <i
                    class="fas fa-chevron-left"
                    v-show="previousApartmentId !== '0'"
                />
            </div>
            <p class="">
                {{ apartmentPosition }} /
                {{ $store.state.account.apartmentsCount }}
            </p>
            <div class="right-switch" @click="nextApartment">
                <i
                    class="fas fa-chevron-right"
                    v-show="nextApartmentId !== '0'"
                />
            </div>
        </div>
    </div>
    <!-- Apartment Logo With Switch Apartment -->
    <section class="image mobile">
        <div class="picto">
            <div class="left-switch" @click="previousApartment">
                <i
                    class="fas fa-chevron-left"
                    :class="{ hidden: previousApartmentId === '0' }"
                />
            </div>
            <div class="big-picture">
                <i class="fas fa-door-closed apartment-picto"></i>
            </div>
            <div class="icons-residence" v-if="ipass">
                <span>
                    <img
                        alt="ipass icon"
                        class="ipass-icon"
                        :src="imageBaseURL + 'Images/ipass_icon.png'"
                    />
                </span>
            </div>
            <div class="right-switch" @click="nextApartment">
                <i
                    class="fas fa-chevron-right"
                    :class="{ hidden: nextApartmentId === '0' }"
                />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Infos",
    props: [
        "name",
        "formattedFloor",
        "ipass",
        "comment",
        "nextApartmentId",
        "previousApartmentId",
        "apartmentPosition",
    ],
    data() {
        return {
            imageBaseURL: process.env.VUE_APP_IMAGES_URL,
        }
    },

    methods: {
        editAction(value) {
            this.$emit("editAction", value)
        },
        nextApartment() {
            this.nextApartmentId !== "0" ? this.$emit("nextAppartment") : null
        },
        previousApartment() {
            this.previousApartmentId !== "0"
                ? this.$emit("previousAppartment")
                : null
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";

.hidden {
    visibility: hidden;
}

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }

    .left-switch {
        cursor: pointer;
        z-index: 2;

        i {
            padding: 1em;
            font-size: $big;
        }
    }

    .apartment-picto {
        font-size: $immense;
        z-index: 0;
    }

    .right-switch {
        cursor: pointer;
        z-index: 2;

        i {
            font-size: $big;
            padding: 1em;
        }
    }

    .hidden {
        visibility: hidden;
    }
}

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}

.mobile-apart-switch {
    @media all and (min-width: 1024px) {
        display: none;
    }

    @media screen and (max-width: 450px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }

    place-self: start;
    display: flex;
    align-items: center;
    padding: 0 1em;
    padding-bottom: 1em;

    p {
        margin: 0;
        padding-right: 0.5em;
    }

    .apart-info {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey-lighter;
        border-radius: 0.5em;
        padding: 0.5em;

        .left-switch {
            padding: 0 1em;
            cursor: pointer;
            display: flex;
            align-items: center;

            i {
                font-size: $big;
            }
        }

        p {
            padding: 0;
        }

        .right-switch {
            padding: 0 1em;
            cursor: pointer;
            display: flex;
            align-items: center;

            i {
                font-size: $big;
            }
        }
    }
}
</style>
