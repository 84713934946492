<template>
    <div v-show="!isLoading" class="panels">
        <LeftMenu
            :entityParent="entityParent"
            :entityType="entityType"
            :activeTab="activeTab"
            :id="id"
            :name="name"
            :comment="comment"
            :liberale="liberale"
            :ipass="ipass"
            :floor="formattedFloor"
            :idParent="idParent"
            :manage="manage"
            :nb_connectit="nb_connectit"
            :lora="lora"
            :nextApartmentId="nextApartmentId"
            :previousApartmentId="previousApartmentId"
            :apartmentPosition="apartmentPosition"
            @editAction="editAction"
            @getConnectit="getConnectit"
            @changeAppart="goToApartment"
            @updatedActiveTab="updateActiveTab"
        />
        <div class="content-panel">
            <v3-warning
                v-if="activeTab === 'authorizations' || activeTab === 'codes'"
            />
            <Home
                v-show="activeTab === 'details'"
                :isLoading="isLoading"
                :activeTab="activeTab"
                :path="path"
                :keys="keys"
                :nb_keys="nb_keys"
                :nb_residents="nb_residents"
                :residents="residents"
                :name="name"
                :comment="comment"
                :id="id"
                :lora="lora"
                :ipass="ipass"
                :nb_connectit="nb_connectit"
                :nextApartmentId="nextApartmentId"
                :previousApartmentId="previousApartmentId"
                :apartmentPosition="apartmentPosition"
                :manage="manage"
                :liberale="liberale"
                :floor="floor"
                :residentElements="residentElements"
                :keyElements="keyElements"
                :entity="entity"
                @showMoreResidents="showMoreResident"
                @changeAppart="goToApartment"
                @showMoreKeys="showMoreKeys"
                @fob-deleted="refreshFobs"
                @refreshResidents="refreshResidents"
            />
            <!-- AUTHORIZATIONS -->
            <div
                id="v1-auths-bloc"
                class="tab-panel"
                v-show="activeTab === 'authorizations'"
            >
                <loader class="v1-tab-loader"></loader>
            </div>
        </div>
    </div>
    <div v-show="isLoading" class="panels-loading">
        <loader></loader>
    </div>
</template>

<script>
import { getApartmentSummaryById } from "@/services/intratone/apartment.js"
import { getResidents } from "@/services/intratone/resident.js"
import { getStairwellSummaryById } from "@/services/intratone/stairwell.js"
import {
    getResidenceSubaccountsById,
    getResidenceSummaryById,
} from "@/services/intratone/residence.js"
import { getFobs } from "@/services/intratone/fob.js"
import { useToast } from "vue-toastification"
import { entityType } from "@/enums"
import { mapState } from "vuex"

import httpIntratone from "@/services/httpIntratone.js"
import Loader from "@/components/basic/Loader.vue"
import v1mixin from "@/mixins/v1.js"
import Home from "./Home.vue"
import LeftMenu from "./LeftMenu.vue"

const toast = useToast()

export default {
    name: "ApartmentDetails",
    components: {
        Loader,
        Home,
        LeftMenu,
    },
    mixins: [v1mixin],
    data() {
        return {
            isLoading: true,
            // Parent type and data
            entityParent: null,
            idParent: null,
            // Entity path
            path: null,
            // Entity type and data
            entity: entityType.APARTMENT,
            id: null,
            idRes: null,
            idCage: null,
            name: null,
            comment: null,
            floor: null,
            type: null,
            manage: null,
            liberale: null,
            ipass: null,
            // Connectit
            nb_connectit: 0,
            // Accesses
            accesses: [],
            // Keys
            keys: [],
            nb_keys: 0,
            // Residents
            residents: [],
            nb_residents: 0,
            // Active tab
            activeTab: "details",
            // Show all accesses
            showAllAccesses: false,
            // Types of entity
            entityType,
            residentPage: 1,
            residentTotalPage: 1,
            accessoryPage: 1,
            accessoryTotalPage: 1,
            residentElements: 3,
            keyElements: 6,
            lora: [],
            previousApartmentId: "",
            nextApartmentId: "",
            apartmentPosition: "1",
            //Sub-accounts data
            subaccounts: [],
            agences: [],
        }
    },
    computed: {
        ...mapState({
            displayKeys: (state) => state.account.displayKeyCard,
        }),
        formattedFloor() {
            const floorNumber = parseInt(this.floor, 10)
            if (isNaN(floorNumber)) {
                return "--"
            } else {
                if (floorNumber >= 0) {
                    return this.$t("entityDetails.floor", {
                        count: floorNumber,
                        suffix: this.getSuffixForEN(floorNumber),
                    })
                } else {
                    return this.$t("entityDetails.basement", {
                        count: -floorNumber,
                        suffix: this.getSuffixForEN(-floorNumber),
                    })
                }
            }
        },

        checkRightAdd() {
            return (
                this.checkRight("G_FICHES") ||
                this.checkRight("G_KEY") ||
                this.checkRight("G_KEY_MOBIL") ||
                this.checkRight("G_KIBOLT") ||
                this.checkRight("G_CONNECTIT")
            )
        },
    },
    async created() {
        await this.load()
    },
    watch: {
        $route() {
            if (this.$route.name === "Apartment") {
                this.load()
            }
        },
        residents(newVal, oldVal) {
            if (oldVal.length > newVal.length) {
                this.residentPage = 1
                this.residentTotalPage = 1
            }
        },
        keys(newVal, oldVal) {
            if (oldVal.length > newVal.length) {
                this.accessoryPage = 1
                this.accessoryTotalPage = 1
            }
        },
    },
    methods: {
        getSuffixForEN(number) {
            if (number === 1) {
                return "st"
            } else if (number === 2) {
                return "nd"
            } else if (number === 3) {
                return "rd"
            } else if (number >= 4 && number !== 0) {
                return "th"
            }
        },
        showMoreKeys() {
            getFobs({
                idappart: this.id,
                page: this.accessoryPage + 1,
                limit: this.keyElements,
            }).then((response) => {
                response.data.list.forEach((newKey) => {
                    if (
                        this.keys.find((exist) => exist.id === newKey.id) ===
                        undefined
                    ) {
                        this.keys.push(newKey)
                    }
                })
                this.accessoryPage = parseInt(response.data._page)
                this.accessoryTotalPage = parseInt(response.data._pages)
            })
        },
        showMoreResident() {
            getResidents({
                idappart: this.id,
                page: this.residentPage + 1,
                limit: this.residentElements,
            }).then((response) => {
                response.data.list.forEach((newResidents) => {
                    if (
                        this.residents.find(
                            (exist) => exist.id === newResidents.id
                        ) === undefined
                    ) {
                        this.residents.push(newResidents)
                    }
                })
                this.residentPage = parseInt(response.data._page)
                this.residentTotalPage = parseInt(response.data._pages)
            })
        },
        createAction() {
            this.openV1Modal("data/appart/appart_add", "Act_Zone")
        },
        editAction(value) {
            if (value === 1) {
                this.goToV1View("data/appart/appart/edit=1&id=" + this.id)
            } else {
                this.goToV1View(
                    "data/residence/load/edit=1&id=" + this.path.residence.id
                )
            }
        },
        getConnectit() {
            this.goToV1View("data/lora/appart_devices")
        },
        updateActiveTab(e = "details") {
            this.activeTab = e
            if (this.activeTab === "authorizations") {
                this.openV1Modal(
                    "data/autorisation/autos/hide_bar=1&res=" + this.idRes,
                    "v1-auths-bloc"
                )
            } else if (this.activeTab === "codes") {
                this.openV1Modal(
                    "data/codes/code_acces/hide_bar=1&res=" + this.idRes,
                    "v1-codes-bloc"
                )
            } else if (this.activeTab === "subaccounts") {
                this.loadSubaccounts()
            }
        },
        goToApartment(e) {
            let route = {
                name: "Apartment",
                params: {
                    id: e,
                    ...(this.activeTab !== "details" && {
                        tab: this.activeTab,
                    }),
                },
            }
            this.$router.push(route)
        },
        async load() {
            this.isLoading = true
            this.entityParent = null
            this.path = null
            this.idParent = null
            this.id = null
            this.name = null
            this.comment = null
            this.floor = null
            this.type = null
            this.keys = []
            this.nb_keys = 0
            this.residents = []

            // V1 site request in order to set v1 site session with apartment

            await this.loadApartment()
            await this.loadAppartsCount()

            this.isLoading = false
        },
        async loadApartment() {
            try {
                // Getting apartment
                let apartmentResponse = await getApartmentSummaryById(
                    this.$route.params.id
                )
                if (apartmentResponse === null) {
                    throw new Error()
                }
                if (apartmentResponse.state === "error") {
                    if (apartmentResponse.code === "API_RESTRICTED") {
                        this.$router.push({
                            path: "/error/401",
                        })
                    } else {
                        this.$router.push({
                            path: "/error/404",
                        })
                    }
                    return
                }

                // Apartment data
                this.id = apartmentResponse.data.appart.id
                this.name = apartmentResponse.data.appart.nom
                this.comment = apartmentResponse.data.appart.comments
                this.floor = apartmentResponse.data.appart.etage
                this.path = apartmentResponse.data.path
                this.entityParent = this.path.cage
                    ? entityType.STAIRWELL
                    : entityType.RESIDENCE
                this.idParent = this.path.cage
                    ? this.path.cage.id
                    : this.path.residence.id
                this.idRes = this.path.residence.id
                this.idCage = this.path.cage
                    ? this.path.cage.id
                    : this.path.residence.id
                this.manage = apartmentResponse.data.appart.loc === "1"
                this.liberale = apartmentResponse.data.appart.liberale === "1"
                this.ipass = apartmentResponse.data.appart.robot === "1"
                this.lora = apartmentResponse.data.appart.lora
                this.nextApartmentId =
                    apartmentResponse.data.appart.next_appart_id
                this.previousApartmentId =
                    apartmentResponse.data.appart.prev_appart_id
                this.apartmentPosition = apartmentResponse.data.appart.position
                this.$store.commit("account/setAppartId", parseInt(this.id))

                // Connect.it
                if (apartmentResponse.data.appart.lora) {
                    let counts_lora = apartmentResponse.data.appart.lora
                    this.nb_connectit = counts_lora.lora_count
                }

                // Keys
                this.keys = apartmentResponse.data.cles.list
                if (this.keys.length > this.keyElements) {
                    this.keys = this.keys.splice(0, this.keyElements)
                }
                this.nb_keys = apartmentResponse.data.cles._count

                // Residents
                this.residents = apartmentResponse.data.fiches.list
                if (this.residents.length > this.residentElements) {
                    this.residents = this.residents.splice(
                        0,
                        this.residentElements
                    )
                }
                this.nb_residents = apartmentResponse.data.fiches._count

                const dataFilters = {
                    appart_nom: apartmentResponse.data.appart.nom,
                    appart_bal: apartmentResponse.data.appart.bal,
                    appart_type: apartmentResponse.data.appart.type,
                    appart_path: apartmentResponse.data.path,
                }

                let v1Call = await httpIntratone.post(
                    "data/appart/init.php",
                    `id=${this.$route.params.id}&SID=${
                        this.$store.getters["auth/getIntratoneDev2SID"]
                    }&apartmentDetails=${JSON.stringify(dataFilters)}`
                )
                if (this.$route.params.tab !== "") {
                    this.updateActiveTab(this.$route.params.tab)
                }
            } catch (error) {
                console.log(error)
            }
        },
        async refreshFobs() {
            try {
                const params = { idappart: this.id }
                let fobsListResponse = await getFobs(params)
                if (fobsListResponse === null) {
                    throw new Error()
                }
                if (fobsListResponse.state === "error") {
                    if (fobsListResponse.code === "API_RESTRICTED") {
                        this.$router.push({
                            path: "/error/401",
                        })
                    } else {
                        this.$router.push({
                            path: "/error/404",
                        })
                    }
                    return
                }
                this.keys = fobsListResponse.data.list
                if (this.keys.length > this.keyElements) {
                    this.keys = this.keys.splice(0, this.keyElements)
                }
                this.nb_keys = fobsListResponse.data._count
            } catch (error) {
                console.log(error)
            }
        },
        async refreshResidents() {
            try {
                this.isLoading = true
                const params = { idappart: this.id }
                let residentsListResponse = await getResidents(params)
                if (residentsListResponse === null) {
                    this.isLoading = false
                    throw new Error()
                }
                if (residentsListResponse.state === "error") {
                    if (residentsListResponse.code === "API_RESTRICTED") {
                        this.$router.push({
                            path: "/error/401",
                        })
                    } else {
                        this.$router.push({
                            path: "/error/404",
                        })
                    }
                    this.isLoading = false
                    return
                }
                this.residents = residentsListResponse.data.list
                if (this.residents.length > this.residentElements) {
                    this.residents = this.residents.splice(
                        0,
                        this.residentElements
                    )
                }
                this.nb_residents = residentsListResponse.data._count
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },
        async loadSubaccounts() {
            try {
                let subaccountsResponse = await getResidenceSubaccountsById(
                    this.idRes
                )
                if (subaccountsResponse === null) {
                    throw new Error()
                }
                this.subaccounts = subaccountsResponse.data.subaccounts
                this.agences = subaccountsResponse.data.agences
            } catch (error) {
                console.log(error)
            }
        },
        async loadAppartsCount() {
            try {
                let appartsCountResponse = this.path.cage
                    ? await getStairwellSummaryById(this.idCage)
                    : await getResidenceSummaryById(this.idCage)
                if (appartsCountResponse === null) {
                    throw new Error()
                }
                if (appartsCountResponse.state === "error") {
                    if (appartsCountResponse.code === "API_RESTRICTED") {
                        this.$router.push({
                            path: "/error/401",
                        })
                    } else {
                        this.$router.push({
                            path: "/error/404",
                        })
                    }
                } else {
                    this.$store.commit(
                        "account/setApartmentsCount",
                        appartsCountResponse.data.apparts._count
                    )
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";

#v1-auths-bloc :deep() {
    @import "./../../assets/scss/v1-scss/style.scss";
    @import "./../../assets/scss/v1-scss/datepicker.scss";
    @import "./../../assets/scss/v1-scss/cle.scss";
    @import "./../../assets/scss/v1-scss/bootstrap/css/bootstrap.scss";
    @import "./../../assets/scss/v1-scss/bootstrap/css/bootstrap.min.scss";
    text-align: center;
}

#v1-codes-bloc :deep() {
    @import "./../../assets/scss/v1-scss/style.scss";
    @import "./../../assets/scss/v1-scss/datepicker.scss";
    @import "./../../assets/scss/v1-scss/cle.scss";
    @import "./../../assets/scss/v1-scss/bootstrap/css/bootstrap.scss";
    @import "./../../assets/scss/v1-scss/bootstrap/css/bootstrap.min.scss";
    text-align: center;
}
@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}
@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}

.keys-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.keys-list {
    gap: 0px !important;
}

.keys-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
}

.key-list-title {
    width: 100%;
    display: grid !important;
    grid-template-columns: 1fr 3fr 3fr 3fr 1fr;
    font-size: $normal;
    border-bottom: 1px solid $grey-light;
    padding: 0.5em 0;

    div {
        font-family: $font_avenir_heavy;
        color: $grey-darker;
    }

    div:nth-child(5) {
        display: flex;
        justify-content: end;
    }
}
</style>
