<template>
    <!-- DETAILS -->
    <div class="tab-panel">
        <ResidenceBreadcrumb :path="path" />

        <div class="informations-bloc blueType no-mobile">
            <Infos
                :name="name"
                :formattedFloor="formattedFloor"
                :comment="comment"
                :nextApartmentId="nextApartmentId"
                :previousApartmentId="previousApartmentId"
                :apartmentPosition="apartmentPosition"
                @editAction="editAction"
                @nextAppartment="nextApartment"
                @previousAppartment="previousApartment"
            />
        </div>

        <!-- ACTIONS -->
        <div class="actions">
            <!-- create -->
            <basic-button
                class="action create"
                color="orange"
                @click="createAction()"
                v-if="checkRightAdd"
            >
                <i class="fas fa-plus"></i>
                <span class="mobile">
                    {{ $t("entityDetails.actions.create") }}
                </span>
            </basic-button>
        </div>

        <!-- RESIDENTS -->
        <h1 class="panel-title keys-title">
            {{
                $t("entityDetails.resident", {
                    count: parseInt(nb_residents),
                })
            }}
        </h1>
        <div class="residents" v-if="residents.length > 0">
            <resident-card
                v-for="item in residents"
                v-on:resident-deleted="refreshResidents()"
                :key="item.id"
                :resident="item"
            >
            </resident-card>
        </div>
        <div class="residents" v-else>
            <resident-card :isCreator="true"></resident-card>
        </div>
        <div
            class="accesses-show-button"
            v-if="
                parseInt(nb_residents) > residentElements &&
                residents.length % residentElements === 0 &&
                parseInt(nb_residents) !== residents.length
            "
        >
            <button @click="showMoreResidents">
                <span>
                    {{ $t("button.seeMore") }}
                    <i class="fas fa-chevron-circle-down"></i>
                </span>
            </button>
        </div>

        <!-- KEYS -->
        <div class="keys-wrapper">
            <h1 class="panel-title keys-title">
                {{
                    $t("entityDetails.accessories", {
                        count: parseInt(nb_keys),
                    })
                }}
            </h1>
            <div>
                <DisplayModeKeyCard />
            </div>
        </div>
        <div :class="'keys-' + display" v-if="keys.length > 0">
            <div v-if="display === 'list'" class="key-list-title">
                <div></div>
                <div>{{ $t("centralUnitsList.arrayColumns.type") }}</div>
                <div>{{ $t("centralUnitsList.arrayColumns.serial") }}</div>
                <div>{{ $t("bloc_erp.color") }}</div>
                <div>{{ $t("agencesList.arrayColumns.comment") }}</div>
                <div>{{ $t("centralUnitsList.arrayColumns.option") }}</div>
            </div>
            <key-card
                v-for="item in keys"
                v-on:fob-deleted="$emit('fob-deleted')"
                :key="item.id"
                :keyObject="item"
            >
            </key-card>
        </div>
        <div class="keys" v-else>
            <key-card :isCreator="true"></key-card>
        </div>
        <div
            class="accesses-show-button"
            v-if="
                parseInt(nb_keys) > keyElements &&
                keys.length % keyElements === 0 &&
                parseInt(nb_keys) !== keys.length
            "
        >
            <button @click="showMoreKeys">
                <span>
                    {{ $t("button.seeMore") }}
                    <i class="fas fa-chevron-circle-down"></i>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification"

import ResidentCard from "@/components/entities/ResidentCard.vue"
import ResidenceBreadcrumb from "@/components/entities/ResidenceBreadcrumb.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import KeyCard from "@/components/entities/KeyCard.vue"
import v1mixin from "@/mixins/v1.js"
import Infos from "@/views/apartment/Infos"
import DisplayModeKeyCard from "@/components/DisplayModeKeyCard"

import { mapState } from "vuex"

const toast = useToast()

export default {
    name: "AppartmentHome",
    components: {
        Infos,
        BasicButton,
        KeyCard,
        ResidentCard,
        ResidenceBreadcrumb,
        DisplayModeKeyCard,
    },
    mixins: [v1mixin],
    props: {
        isLoading: {
            type: Boolean,
        },
        activeTab: {
            type: String,
        },
        path: {
            type: Object,
            default: () => {
                return {}
            },
        },
        manage: {
            type: Boolean,
        },
        name: {
            type: String,
        },
        floor: {
            type: String,
        },
        comment: {
            type: String,
        },
        residents: {
            type: Array,
            default: () => [],
        },
        nb_residents: {
            type: [String, Number],
        },
        nb_keys: {
            type: [String, Number],
            default: 0,
        },
        nb_connectit: {
            type: Number,
            default: 0,
        },
        keys: {
            type: Array,
            default: () => [],
        },
        residentElements: {
            type: Number,
            default: 3,
        },
        keyElements: {
            type: Number,
            default: 6,
        },
        lora: {
            type: Object,
            default: () => {},
        },
        entity: {
            type: String,
            default: "",
        },
        liberale: {
            type: Boolean,
            default: false,
        },
        ipass: {
            type: Boolean,
            default: false,
        },
        nextApartmentId: {
            type: String,
            default: "",
        },
        previousApartmentId: {
            type: String,
            default: "",
        },
        apartmentPosition: {
            type: String,
            default: "0",
        },
        id: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            // Parent type and data
            entityParent: null,
            idParent: null,
            type: null,
            accesses: [],
            // Show all accesses
            showAllAccesses: false,
            residentPage: 1,
            residentTotalPage: 1,
            accessoryPage: 1,
            accessoryTotalPage: 1,
        }
    },
    computed: {
        formattedFloor() {
            const floorNumber = parseInt(this.floor, 10)
            if (isNaN(floorNumber)) {
                return "--"
            } else {
                if (floorNumber >= 0) {
                    return this.$t("entityDetails.floor", {
                        count: floorNumber,
                    })
                } else {
                    return this.$t("entityDetails.basement", {
                        count: -floorNumber,
                    })
                }
            }
        },
        checkRightAdd() {
            return (
                this.checkRight("G_FICHES") ||
                this.checkRight("G_KEY") ||
                this.checkRight("G_KEY_MOBIL") ||
                this.checkRight("G_KIBOLT") ||
                this.checkRight("G_CONNECTIT")
            )
        },
        ...mapState({
            display: (state) => state.account.displayKeyCard,
        }),
    },
    watch: {
        residents(newVal, oldVal) {
            if (oldVal.length > newVal.length) {
                this.residentPage = 1
                this.residentTotalPage = 1
            }
        },
        keys(newVal, oldVal) {
            if (oldVal.length > newVal.length) {
                this.accessoryPage = 1
                this.accessoryTotalPage = 1
            }
        },
    },
    methods: {
        displayTab(tabLabel) {
            this.$emit("displayTab", tabLabel)
        },
        createAction() {
            this.openV1Modal("data/appart/appart_add", "Act_Zone")
        },
        editAction() {
            this.goToV1View("data/appart/appart/edit=1&id=" + this.id)
        },
        getConnectit() {
            this.goToV1View("data/lora/appart_devices")
        },
        getPathAppart(key) {
            if (key === "batiment") {
                return "/building/"
            } else if (key === "cage") {
                return "/stairwell/"
            } else {
                return "/residence/"
            }
        },
        showMoreResidents() {
            this.$emit("showMoreResidents")
        },
        showMoreKeys() {
            this.$emit("showMoreKeys")
        },
        nextApartment() {
            this.$emit("changeAppart", this.nextApartmentId)
        },
        previousApartment() {
            this.$emit("changeAppart", this.previousApartmentId)
        },
        refreshResidents() {
            this.$emit("refreshResidents")
        },
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";
@import "@/assets/scss/global.scss";

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }

    .panels {
        width: 100% !important;
    }
}
.keys-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.keys-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .key-list-title {
        display: grid !important;
        grid-template-columns: 1fr 2fr 2fr 1fr 3fr 1fr;
        font-size: $normal;
        border-bottom: 1px solid $grey-light;
        padding: 0.5em 0;
        width: 100%;

        div {
            font-family: $font_avenir_heavy;
            color: $grey-darker;
        }

        div:nth-child(6) {
            display: flex;
            justify-content: end;
        }
    }
}

.keys-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
}
.panels-loading {
    width: 100% !important;
}

.panels {
    width: 100% !important;
}
</style>
