<template>
    <div class="resident" v-if="!isCreator">
        <div
            class="picto"
            @click="goToV1View('data/fiche/rech=0&id=' + resident.id)"
        >
            <i class="fas fa-user"></i>
        </div>
        <div
            class="informations"
            @click="goToV1View('data/fiche/rech=0&id=' + resident.id)"
        >
            <div class="name">
                <div class="info">
                    <h2>{{ $t("residentCard.surname") }}</h2>
                    <h1>{{ resident.nom }}</h1>
                </div>
            </div>
            <div class="name">
                <div class="info">
                    <h2>{{ $t("residentCard.name") }}</h2>
                    <h1>{{ resident.prenom }}</h1>
                </div>
            </div>
            <div class="phones">
                <div class="info">
                    <h2>{{ $t("residentCard.mainNumber") }}</h2>
                    <h1>
                        {{
                            resident.telp === "" ||
                            resident.telp === null ||
                            resident.telp === undefined
                                ? "-"
                                : resident.telp
                        }}
                    </h1>
                </div>
                <div class="info">
                    <h2>{{ $t("residentCard.sideNumber") }}</h2>
                    <h1>
                        {{
                            resident.tels === "" ||
                            resident.tels === null ||
                            resident.tels === undefined
                                ? "-"
                                : resident.tels
                        }}
                    </h1>
                </div>
            </div>
        </div>
        <div class="options">
            <button
                v-if="checkRight('G_FICHES') || checkRight('G_NAME_MOVE')"
                class="options-button dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
            >
                <i class="fas fa-ellipsis-v"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="options-button">
                <a
                    @click="goToV1View('data/fiche/rech=0&id=' + resident.id)"
                    class="dropdown-item"
                    v-if="checkRight('G_FICHES') || checkRight('G_NAME_MOVE')"
                >
                    <i class="fas fa-pencil-alt"></i>
                    {{ $t("button.modify") }}
                </a>
                <a
                    @click="deleteResident()"
                    class="dropdown-item"
                    v-if="checkRight('G_FICHES')"
                >
                    <i class="fas fa-trash-alt"></i>
                    {{ $t("button.delete") }}
                </a>
            </ul>
        </div>
    </div>
    <div
        class="resident-creator"
        @click="openV1Modal('data/appart/appart_add', 'Act_Zone')"
        v-else-if="checkRight('G_FICHES')"
    >
        <i class="fas fa-plus"></i>
        <h1>{{ $t("residentCard.create") }}</h1>
    </div>
</template>

<script>
import v1mixin from "@/mixins/v1.js"

import { deleteResident } from "@/services/intratone/resident.js"

export default {
    name: "ResidentCard",
    mixins: [v1mixin],
    components: {},
    props: {
        resident: {
            type: Object,
            default: function () {
                return null
            },
        },
        isCreator: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async deleteResident() {
            try {
                if (
                    window.confirm(
                        this.$t("residentCard.delete", {
                            nom: this.resident.nom,
                        })
                    )
                ) {
                    let residentDelResponse = await deleteResident(
                        this.resident.id
                    )
                    if (residentDelResponse === null) {
                        throw new Error()
                    } else if (residentDelResponse.state === "error") {
                        if (residentDelResponse.code === "API_RESTRICTED") {
                            this.$router.push({
                                path: "/error/401",
                            })
                        } else {
                            this.$router.push({
                                path: "/error/404",
                            })
                        }
                        return
                    } else {
                        this.$emit("resident-deleted")
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
    created() {},
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.resident {
    height: 180px;
    display: flex;
    border-radius: 30px;
    box-shadow: 0px 0 15px 0 rgb(0 0 0 / 15%);
    cursor: pointer;

    @media all and (min-width: 768px) and (max-width: 1024px) {
        width: 42%;
    }

    @media all and (max-width: 768px) {
        width: 100%;
        max-width: 380px;
    }

    .picto {
        width: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 !important;

        @media all and (min-width: 1024px) {
            width: 180px;

            i {
                font-size: $immense !important;
                margin: 0 !important;
            }
        }

        @media all and (min-width: 768px) and (max-width: 1024px) {
            width: 90px;

            i {
                font-size: $veryBig !important;
                margin: 0 !important;
            }
        }

        @media all and (max-width: 768px) {
            width: 45px;

            i {
                display: none;
            }
        }
    }

    .informations {
        width: 280px;

        .info {
            margin-right: 30px;

            h1 {
                font-size: $small;
                font-family: $font_avenir_heavy;
            }

            h2 {
                font-size: $verySmall;
            }
        }

        .name {
            margin: 15px 0 15px 0;
            height: 40px;
            display: flex;
            align-items: center;
        }

        .phones {
            margin: 10px 0 10px 0;
            height: 40px;
            display: flex;
            align-items: center;
        }
    }

    .options {
        width: 60px;

        button {
            background-color: $white;
            border-radius: 30px;
            border: none;
            padding: 14px 12px 10px 12px;
            margin: 20px 0 0 0;
            transition: background-color 200ms;

            i {
                font-size: $big;
                color: $blue-neutral;
                transition: color 200ms;
            }
        }

        button:hover {
            background-color: $grey-light;

            i {
                color: $orange-neutral;
            }
        }

        .dropdown-item {
            font-size: $small;
            transition: background-color 200ms, color 200ms, padding 200ms;
            color: $blue-neutral;
            font-family: "Avenir Heavy";

            i {
                font-size: $normal;
                margin-right: 15px;
            }
        }

        .dropdown-item:hover {
            cursor: pointer;
            color: $orange-neutral;
            background-color: $grey-lighter;
            padding-left: 30px;
        }

        .dropdown-toggle:after {
            display: none;
        }
    }
}

.resident-creator {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    gap: 0.5em;
    border-radius: 15px;
    background-color: $orange-neutral;
    color: $white;
    border: none;
    transition: background-color 200ms;
    cursor: pointer;
    padding: 1em 3em;

    i {
        color: $white;
        font-size: $big;
    }

    h1 {
        margin: 0px !important;
        font-size: $normal;
        font-family: $font_avenir_heavy;
        color: $white;
    }
}

.resident-creator:hover {
    background-color: $orange-light;
}
</style>
